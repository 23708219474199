require("./bootstrap");
import "@mdi/font/css/materialdesignicons.css";

window.Vue = require("vue");

import Vue from "vue";
import Vuelidate from "vuelidate";
import VCalendar from "v-calendar";

Vue.use(Vuelidate);
Vue.use(VCalendar, {
    componentPrefix: "vc",
    locale: "cs"
});

Vue.component(
    "contact-form",
    require("./frontend/components/ContactForm.vue").default
);
Vue.component(
    "calendar",
    require("./frontend/components/Calendar.vue").default
);
Vue.component("slider", require("./frontend/components/App.vue").default);
Vue.component(
    "photo-gallery",
    require("./frontend/components/InstagramPosts.vue").default
);
Vue.component(
    "weather",
    require("./frontend/components/WeatherWidget.vue").default
);
Vue.component(
    "news",
    require("./frontend/components/NewsLetterForm.vue").default
);

Vue.component(
    "third-party-content",
    require("./frontend/components/ThirdPartyContent.vue").default
);

const app = new Vue({
    el: "#app"
});
