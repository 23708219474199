<template>
  <div id="mainapp">
    <Slider :images="images" :intervalDuration="slideDuration"/>
  </div>
</template>

<script>
import Slider from "./slider/Slider.vue";
export default {
  components :{
    Slider
  },
  data(){
    return{
      images : [
        {
          id: 0,
          url : "/photoGallery/image1.jpg",
          title: "Lightning"
        },
        {
          id: 1,
          url : "/photoGallery/image2.jpg",
          title: "Batman on the roof"
        }
      ],
      slideDuration : 4000
    }
  }
}
</script>

<style lang="scss">
body{
  margin:0;
}
#mainapp{
  //height: 500px;
  width: 100%;
  height: 100vh;
}
</style>