<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-10">
                <div class="card card-default">
                    <div class="card-body">
                        <div v-if="message">
                            <div
                                :class="'alert alert-' + message.type"
                                role="alert"
                                v-if="message"
                            >
                                {{ message.text }}
                            </div>
                            <button
                                type="button"
                                class="btn btn-primary"
                                @click.prevent="message = null"
                            >
                                Nová přihláška
                            </button>
                        </div>
                        <form id="form" @submit.prevent="save">
                            <div v-if="!message">
                                <h2>Informace o účastníkovi</h2>
                                <div class="form-group">
                                    <label
                                        for="name"
                                        :class="{
                                            'itb-label-error': $v.name.$error
                                        }"
                                        >Jméno:</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="name"
                                        v-model.trim="name"
                                        @input="$v.name.$touch()"
                                        :class="{
                                            'itb-field-error': $v.name.$error
                                        }"
                                        ref="name"
                                    />
                                    <div v-if="$v.name.$dirty">
                                        <p
                                            class="itb-error-message"
                                            v-if="!$v.name.required"
                                            aria-live="assertive"
                                        >
                                            Jméno nesmí být prázdné.
                                        </p>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label
                                        for="validationDefault02"
                                        :class="{
                                            'itb-label-error': $v.surname.$error
                                        }"
                                        >Příjmení:</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="validationDefault02"
                                        v-model.trim="surname"
                                        @input="$v.surname.$touch()"
                                        :class="{
                                            'itb-field-error': $v.surname.$error
                                        }"
                                        ref="surname"
                                    />
                                    <div v-if="$v.surname.$dirty">
                                        <p
                                            class="itb-error-message"
                                            v-if="!$v.surname.required"
                                        >
                                            Příjmení nesmí být prázdné.
                                        </p>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label
                                        for="birth"
                                        :class="{
                                            'itb-label-error': $v.birth.$error
                                        }"
                                        >Rodné číslo uvádějte bez
                                        lomítka:</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="birth"
                                        v-model.trim="birth"
                                        @input="$v.birth.$touch()"
                                        :class="{
                                            'itb-field-error': $v.birth.$error
                                        }"
                                        ref="birth"
                                    />
                                    <div v-if="$v.birth.$dirty">
                                        <p
                                            class="itb-error-message"
                                            v-if="!$v.birth.required"
                                        >
                                            Rodné číslo nesmí být prázdné.
                                        </p>
                                        <p
                                            class="itb-error-message"
                                            v-if="
                                                !$v.birth.minLength ||
                                                    !$v.birth.maxLength
                                            "
                                        >
                                            Rodné číslo musí mít
                                            {{ $v.birth.$params.minLength.min }}
                                            čísel
                                        </p>
                                        <p
                                            class="itb-error-message"
                                            v-if="!$v.birth.numeric"
                                        >
                                            Rodné číslo musí být číselné.
                                        </p>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label
                                        for="validationDefault04"
                                        :class="{
                                            'itb-label-error':
                                                $v.insurance.$error
                                        }"
                                        >Zdravotní pojišťovna:</label
                                    >
                                    <select
                                        class="custom-select"
                                        v-model.trim="insurance"
                                        id="validationDefault04"
                                        @input="$v.insurance.$touch()"
                                        :class="{
                                            'itb-field-error':
                                                $v.insurance.$error
                                        }"
                                        ref="insurance"
                                    >
                                        <option :value="undefined"
                                            >Vybrat...</option
                                        >
                                        <option
                                            >111 Všeobecná zdravotní pojišťovna
                                            ČR</option
                                        >
                                        <option
                                            >201 Vojenská zdravotní pojišťovna
                                            ČR</option
                                        >
                                        <option
                                            >205 Česká průmyslová zdravotní
                                            pojišťovna</option
                                        >
                                        <option
                                            >207 Oborová zdravotní poj. zam.
                                            bank, poj. a stav.</option
                                        >
                                        <option
                                            >209 Zaměstnanecká pojišťovna
                                            Škoda</option
                                        >
                                        <option
                                            >211 Zdravotní pojišťovna
                                            ministerstva vnitra ČR</option
                                        >
                                        <option
                                            >213 Revírní bratrská pokladna,
                                            zdrav. pojišťovna</option
                                        >
                                    </select>
                                    <div v-if="$v.insurance.$dirty">
                                        <p
                                            class="itb-error-message"
                                            v-if="!$v.insurance.required"
                                        >
                                            Pojištění nesmí být prázdné.
                                        </p>
                                    </div>
                                </div>

                                <h2>Kontaktní údaje</h2>
                                <div class="form-group">
                                    <label
                                        for="validationDefault05"
                                        :class="{
                                            'itb-label-error':
                                                $v.fatherName.$error
                                        }"
                                        >Jméno a příjmení rodiče (zákonného
                                        zástupce):</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="validationDefault05"
                                        v-model.trim="fatherName"
                                        @input="$v.fatherName.$touch()"
                                        :class="{
                                            'itb-field-error':
                                                $v.fatherName.$error
                                        }"
                                        ref="fatherName"
                                    />
                                    <div v-if="$v.fatherName.$dirty">
                                        <p
                                            class="itb-error-message"
                                            v-if="!$v.fatherName.required"
                                        >
                                            Jméno a příjmení rodiče nesmí být
                                            prázdné.
                                        </p>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label
                                        for="validationDefault06"
                                        :class="{
                                            'itb-label-error': $v.phone.$error
                                        }"
                                        >Telefon-pouze bez předčíslí:</label
                                    >
                                    <input
                                        type="tel"
                                        class="form-control"
                                        id="validationDefault06"
                                        v-model.trim="phone"
                                        @input="$v.phone.$touch()"
                                        :class="{
                                            'itb-field-error': $v.phone.$error
                                        }"
                                        ref="phone"
                                    />
                                    <div v-if="$v.phone.$dirty">
                                        <p
                                            class="itb-error-message"
                                            v-if="!$v.phone.required"
                                        >
                                            Telefonní číslo nesmí být prázdné.
                                        </p>
                                        <p
                                            class="itb-error-message"
                                            v-if="!$v.phone.minLength"
                                        >
                                            Telefonní číslo musí mít alespoň
                                            {{ $v.phone.$params.minLength.min }}
                                            čísla.
                                        </p>
                                        <p
                                            class="itb-error-message"
                                            v-if="!$v.phone.numeric"
                                        >
                                            Telefonní číslo musí být číselné.
                                        </p>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label
                                        for="validationDefault07"
                                        :class="{
                                            'itb-label-error': $v.email.$error
                                        }"
                                        >E-mail:</label
                                    >
                                    <input
                                        type="email"
                                        class="form-control"
                                        id="validationDefault07"
                                        v-model.trim="email"
                                        @input="$v.email.$touch()"
                                        :class="{
                                            'itb-field-error': $v.email.$error
                                        }"
                                        ref="email"
                                    />
                                    <div v-if="$v.email.$dirty">
                                        <p
                                            class="itb-error-message"
                                            v-if="!$v.email.email"
                                        >
                                            Prosím zadejte platnou emailovou
                                            adresu
                                        </p>
                                        <p
                                            class="itb-error-message"
                                            v-if="!$v.email.required"
                                        >
                                            E-mail nesmí být prázdný.
                                        </p>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label
                                        for="validationDefault08"
                                        :class="{
                                            'itb-label-error': $v.street.$error
                                        }"
                                        >Ulice:</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="validationDefault08"
                                        v-model.trim="street"
                                        @input="$v.street.$touch()"
                                        :class="{
                                            'itb-field-error': $v.street.$error
                                        }"
                                        ref="street"
                                    />
                                    <div v-if="$v.street.$dirty">
                                        <p
                                            class="itb-error-message"
                                            v-if="!$v.street.required"
                                        >
                                            Ulice nesmí být prázdná.
                                        </p>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label
                                        for="validationDefault09"
                                        :class="{
                                            'itb-label-error': $v.city.$error
                                        }"
                                        >Město:</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="validationDefault09"
                                        v-model.trim="city"
                                        @input="$v.city.$touch()"
                                        :class="{
                                            'itb-field-error': $v.city.$error
                                        }"
                                        ref="city"
                                    />
                                    <div v-if="$v.city.$dirty">
                                        <p
                                            class="itb-error-message"
                                            v-if="!$v.city.required"
                                        >
                                            Město nesmí být prázdné.
                                        </p>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label
                                        for="validationDefault10"
                                        :class="{
                                            'itb-label-error': $v.zipCode.$error
                                        }"
                                        >PSČ:</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="validationDefault10"
                                        v-model.trim="zipCode"
                                        @input="$v.zipCode.$touch()"
                                        :class="{
                                            'itb-field-error': $v.zipCode.$error
                                        }"
                                        ref="zipCode"
                                    />
                                    <div v-if="$v.zipCode.$dirty">
                                        <p
                                            class="itb-error-message"
                                            v-if="!$v.zipCode.required"
                                        >
                                            PSČ nesmí být prázdné.
                                        </p>
                                        <p
                                            class="itb-error-message"
                                            v-if="!$v.zipCode.numeric"
                                        >
                                            PSČ musí být číselné.
                                        </p>
                                    </div>
                                </div>

                                <h2>Informace o kurzu</h2>
                                <div class="form-group">
                                    <label
                                        for="validationDefault11"
                                        :class="{
                                            'itb-label-error': $v.course.$error
                                        }"
                                        >Mám zájem o výuku:</label
                                    >
                                    <select
                                        class="custom-select"
                                        v-model.trim="course"
                                        id="validationDefault11"
                                        @input="$v.course.$touch()"
                                        :class="{
                                            'itb-field-error': $v.course.$error
                                        }"
                                        ref="course"
                                    >
                                        <option :value="undefined"
                                            >Vybrat...</option
                                        >
                                        <option>lyžování</option>
                                        <option>snowboardingu</option>
                                    </select>
                                    <div v-if="$v.course.$dirty">
                                        <p
                                            class="itb-error-message"
                                            v-if="!$v.course.required"
                                        >
                                            Kurz nesmí být prázdný.
                                        </p>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label
                                        for="validationDefault12"
                                        :class="{
                                            'itb-label-error': $v.level.$error
                                        }"
                                        >Stávající zdatnost:</label
                                    >
                                    <select
                                        class="custom-select"
                                        v-model.trim="level"
                                        id="validationDefault12"
                                        @input="$v.level.$touch()"
                                        :class="{
                                            'itb-field-error': $v.level.$error
                                        }"
                                        ref="level"
                                    >
                                        <option :value="undefined"
                                            >Vybrat...</option
                                        >
                                        <option>začátečník</option>
                                        <option>mírně pokročilý</option>
                                        <option>pokročilý</option>
                                    </select>
                                    <div v-if="$v.level.$dirty">
                                        <p
                                            class="itb-error-message"
                                            v-if="!$v.level.required"
                                        >
                                            Stávající zdatnost nesmí být
                                            prázdný.
                                        </p>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label
                                        for="validationDefault13"
                                        :class="{
                                            'itb-label-error':
                                                $v.experience.$error
                                        }"
                                        >Zúčastnil/a se přihlášený/á Veřejné
                                        lyžařské školy v předešlém roce?:</label
                                    >
                                    <select
                                        class="custom-select"
                                        v-model.trim="experience"
                                        id="validationDefault13"
                                        @input="$v.experience.$touch()"
                                        :class="{
                                            'itb-field-error':
                                                $v.experience.$error
                                        }"
                                        ref="experience"
                                    >
                                        <option :value="undefined"
                                            >Vybrat...</option
                                        >
                                        <option>ano</option>
                                        <option>ne</option>
                                    </select>
                                    <div v-if="$v.experience.$dirty">
                                        <p
                                            class="itb-error-message"
                                            v-if="!$v.experience.required"
                                        >
                                            Zkušenosti nesmí být prázdné.
                                        </p>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="validationDefault14"
                                        >Pokud to bude možné zařadit do družstva
                                        společně s (jméno, příjmení, ročník,
                                        max. 2 jména):</label
                                    >
                                    <textarea
                                        type="text"
                                        class="form-control"
                                        id="validationDefault14"
                                        v-model="text"
                                        placeholder="Zpráva"
                                        aria-describedby="textHelp"
                                    >
                                    </textarea>
                                    <small
                                        id="textHelp"
                                        class="form-text text-muted"
                                        >Poslat zprávu.</small
                                    >
                                </div>
                                <div class="form-group">
                                    <div class="form-check">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="invalidCheck3"
                                            v-model="agreement"
                                            required
                                            ref="agreement"
                                        />
                                        <label
                                            class="form-check-label"
                                            for="invalidCheck3"
                                            >Souhlasím výše uvedenýmí podmínkami
                                            Veřejné lyžařské školy Pro dokončení
                                            elektronické přihlášky musíte ihned
                                            uhradit kursovné ve výši
                                            <strong
                                                >{{
                                                    settings.contact_form_price
                                                }},- Kč</strong
                                            >
                                            na účet KL Turnov :
                                            <strong>{{
                                                settings.contact_form_account_number
                                            }}</strong
                                            >. Variabilní symbol=rodné číslo
                                            dítěte,spec. symbol
                                            {{
                                                settings.contact_form_account_specific
                                            }}
                                            - s tímto souhlasím.</label
                                        >
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="form-check">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="invalidCheck2"
                                            v-model="checked"
                                            required
                                            ref="checked"
                                        />
                                        <label
                                            class="form-check-label"
                                            for="invalidCheck2"
                                            >Rodič potvrzuje, že se před
                                            podpisem tohoto prohlášení seznámil
                                            se Zásadami ochrany osobních údajů
                                            Klubu lyžařů Turnov, které jsou
                                            uveřejněny na stránkách
                                            <a href="https://www.klturnov.cz"
                                                >www.klturnov.cz</a
                                            >
                                            a uděluje Klubu lyžařů Turnov
                                            souhlas se zpracováním osobních
                                            údajů svých i svého dítěte v souladu
                                            s těmito zásadami. Současně bere na
                                            vědomí, že poskytnutí jeho osobních
                                            údajů, jakož i osobních údajů
                                            dítěte, je požadováno z důvodu
                                            jejich nezbytnosti pro plnění
                                            závazků ze strany Klubu lyžařů
                                            Turnov, případně jejich poskytnutí
                                            vyžaduje zákon.</label
                                        >
                                    </div>
                                </div>

                                <button
                                    type="submit"
                                    class="btn btn-primary"
                                    :disabled="submitStatus === 'PENDING'"
                                >
                                    Odeslat
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    required,
    minLength,
    maxLength,
    between,
    email,
    numeric,
    minValue
} from "vuelidate/lib/validators";

export default {
    data() {
        return {
            valid: true,
            message: null,
            submitStatus: null,
            name: "",
            surname: "",
            birth: "",
            insurance: "",
            fatherName: "",
            phone: "",
            email: "",
            street: "",
            city: "",
            zipCode: "",
            course: "",
            level: "",
            experience: "",
            text: "",
            agreement: true,
            checked: true,
            settings: []
        };
    },

    validations: {
        name: {
            required
        },
        surname: {
            required
        },
        birth: {
            required,
            minLength: minLength(10),
            maxLength: maxLength(10),
            numeric
        },
        insurance: {
            required
        },
        fatherName: {
            required
        },
        phone: {
            required,
            minLength: minLength(9),
            numeric
        },
        email: {
            required,
            email
        },
        street: {
            required
        },
        city: {
            required
        },
        zipCode: {
            required,
            numeric
        },
        course: {
            required
        },
        level: {
            required
        },
        experience: {
            required
        },
        agreement: {
            required
        }
    },

    created() {
        this.getSettings();
    },

    methods: {
        async getSettings() {
            await axios
                .get("/contactFormSettings")
                .then(response => {
                    this.settings = response.data;
                })
                .catch(error => {
                    console.log(error);
                    this.$refs.SnackMessage.showMessage(
                        "general.error",
                        "error"
                    );
                });
        },
        async save() {
            var toData = {
                name: this.name,
                surname: this.surname,
                birth: this.birth,
                insurance: this.insurance,
                fatherName: this.fatherName,
                phone: this.phone,
                email: this.email,
                street: this.street,
                city: this.city,
                zipCode: this.zipCode,
                course: this.course,
                level: this.level,
                experience: this.experience,
                text: this.text,
                agreement: this.agreement,
                checked: this.checked
            };
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.submitStatus = "ERROR";
                for (let key in Object.keys(this.$v)) {
                    const input = Object.keys(this.$v)[key];
                    if (input.includes("$")) return false;
                    if (this.$v[input].$error) {
                        this.$refs[input].scrollIntoView({
                            behavior: "smooth",
                            block: "center"
                        });
                        break;
                    }
                }
            } else {
                await axios
                    .post("/save", toData)
                    .then(response => {
                        this.message = {
                            text: "Formulář byl úspěšně odeslán",
                            type: "success"
                        };
                        this.$v.$reset();
                        this.name = "";
                        this.surname = "";
                        this.birth = "";
                        this.insurance = "";
                        this.fatherName = "";
                        this.phone = "";
                        this.email = "";
                        this.street = "";
                        this.city = "";
                        this.zipCode = "";
                        this.course = "";
                        this.level = "";
                        this.experience = "";
                        this.text = "";
                        this.agreement = true;
                        this.checked = true;
                        setTimeout(function() {
                            this.message = null;
                        }, 3000);
                    })
                    .catch(error => {
                        console.log(error);

                        this.message = {
                            text: "Bohužel došlo k chybě, zkuste znovu prosím",
                            type: "danger"
                        };

                        setTimeout(function() {
                            this.message = null;
                        }, 3000);
                    });
                this.submitStatus = "PENDING";
                setTimeout(() => {
                    this.submitStatus = "OK";
                }, 500);
            }
        }
    }
};
</script>

<style>
.itb-error-message {
    font-size: 80%;
    color: #f04124;
    margin-bottom: 4px;
}
.itb-label-error {
    color: #f04124;
}
.itb-field-error {
    border-color: #f79483;
    color: #f04124;
}
</style>
